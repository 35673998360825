import { captureException } from '@sentry/react'
import { Links, LinksFunction, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from 'react-router'
import { z } from 'zod'
import NotFoundPage from '~/components/404'
import { ErrorPage } from '~/components/500'
import { Toaster } from '~/components/ui/sonner'
import stylesHref from './globals.css?url'

export const links: LinksFunction = () => {
  return [
    {
      rel: 'stylesheet',
      href: stylesHref,
    },
  ]
}

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  const requiredMessage = 'این فیلد الزامی است.'

  if (issue.code === z.ZodIssueCode.invalid_type || issue.code === z.ZodIssueCode.too_small) {
    return { message: requiredMessage }
  }
  if (issue.code === z.ZodIssueCode.custom) {
    return { message: requiredMessage }
  }
  return { message: ctx.defaultError }
}

z.setErrorMap(customErrorMap)

export const handle = {
  scripts: () => [{ src: 'https://cdn.paddle.com/paddle/paddle.js' }],
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <link href={stylesHref} rel="stylesheet" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#ffffff"></meta>
      </head>
      <body dir="rtl">
        {children}
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError() as Record<string, unknown>
  captureException(error)
  console.error(error)
  if (error.status === 404) return <NotFoundPage />
  return <ErrorPage />
}

export default function App() {
  return <Outlet />
}
