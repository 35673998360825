import { Link } from 'react-router';

export const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[100dvh] px-4 md:px-6 py-12 md:py-24 lg:py-32 gap-10">
      <img src="/images/logo.png" alt="404 Illustration" className="max-w-[300px] mb-8" />
      <div className="text-center space-y-4">
        <h1 className="text-2xl md:text-4xl font-bold">خطایی رخ داده است!</h1>
        <p className="text-gray-500 dark:text-gray-400 max-w-md">با عرض پوزش، مشکلی در پردازش داده به وجود آمده، لطفا بعدا تلاش کنید.</p>
        <Link
          to="/"
          className="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-8 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
        >
          برگشت به صفحه اصلی
        </Link>
      </div>
    </div>
  )
}
